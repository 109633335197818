<template>
	<div>
		<v-footer :elevation="2" fixed color="white" class="pt-5" style="z-index: 10" :style="{'padding-left': isMobile ? '0px' : '60px'}">
				<v-btn
					color="#a5c4fa"
					class="mb-2 mr-1"
					dark
					v-if="preferredBlock"
					@click="footer = true"
				>
					<v-icon class="mr-1">mdi-heart</v-icon
					>{{ preferredBlock.startTime.format("DD/MM/YYYY HH:mm") }}
				</v-btn>
				<v-btn
					color="primary"
					class="mr-1 mb-2"
					v-if="availableBlocks.length"
					@click="footer = true"
				>
					<v-icon class="mr-1">mdi-check-circle</v-icon
					>{{ availableBlocks.length }}
				</v-btn>
				<v-btn
					small
					text
					color="error"
					class="mb-2"
					v-if="unavailableBlocks.length"
					@click="footer = true"
				>
					<v-icon class="mr-1">mdi-close-circle</v-icon
					>{{ unavailableBlocks.length }}
				</v-btn>
			<v-row>
				<v-col>

					<p class="text-right">
					<v-btn color="primary" @click="$emit('save')">Save and Exit</v-btn>
					</p>
				</v-col>
			</v-row>
		</v-footer>
		<v-bottom-sheet v-model="footer">
			<v-card-title>
				Your availability
				<v-spacer></v-spacer>
				<v-btn @click="footer = false" icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-card-title>
			<v-card-text>
				<v-list dense>
					<v-list-item v-if="preferredBlock">
						<v-list-item-icon
							><v-icon color="#a5c4fa">mdi-heart</v-icon></v-list-item-icon
						>
						<v-list-item-content>
							<v-list-item-title>{{
								preferredBlock.startTime.format("dddd, MMMM Do YYYY")
							}}</v-list-item-title>
							<v-list-item-subtitle
								>{{ preferredBlock.startTime.format("HH:mm") }} -
								{{
									preferredBlock.endTime.format("HH:mm")
								}}</v-list-item-subtitle
							>
						</v-list-item-content>
						<v-list-item-action>
							<v-btn icon @click="deleteBlock(preferredBlock)"
								><v-icon>mdi-delete</v-icon></v-btn
							>
						</v-list-item-action>
					</v-list-item>
					<v-list-item
						v-for="(block, i) in availableBlocks"
						:key="`available-${i}`"
					>
						<v-list-item-icon
							><v-icon color="success"
								>mdi-check-circle</v-icon
							></v-list-item-icon
						>
						<v-list-item-content>
							<v-list-item-title>{{
								block.startTime.format("dddd, MMMM Do YYYY")
							}}</v-list-item-title>
							<v-list-item-subtitle
								>{{ block.startTime.format("HH:mm") }} -
								{{
									block.endTime.format("HH:mm")
								}}</v-list-item-subtitle
							>
						</v-list-item-content>
						<v-list-item-action>
							<v-btn icon @click="deleteBlock(block)"
								><v-icon>mdi-delete</v-icon></v-btn
							>
						</v-list-item-action>
					</v-list-item>
					<v-list-item
						v-for="(block, i) in unavailableBlocks"
						:key="`unavailable-${i}`"
					>
						<v-list-item-icon
							><v-icon color="error"
								>mdi-close-circle</v-icon
							></v-list-item-icon
						>
						<v-list-item-content>
							<v-list-item-title>{{
								block.startTime.format("dddd, MMMM Do YYYY")
							}}</v-list-item-title>
							<v-list-item-subtitle
								>{{ block.startTime.format("HH:mm") }} -
								{{
									block.endTime.format("HH:mm")
								}}</v-list-item-subtitle
							>
						</v-list-item-content>
						<v-list-item-action>
							<v-btn icon @click="deleteBlock(block)"
								><v-icon>mdi-delete</v-icon></v-btn
							>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-card-text>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	name: "MobileSchedulerFooter",
	props: {
		value: { type: Array },
	},

	data: () => {
		return {
			footer: false,
		};
	},
	inject: ["isMobile"],
	computed: {
		preferredBlock() {
			let p = this.value.find((block) => block.status == "preferred");
			if (!p) {
				return false;
			}
			return p;
		},
		availableBlocks() {
			return this.value
				.filter((block) => block.status == "available")
				.sort((a, b) => a.startTime.unix() - b.startTime.unix());
		},
		unavailableBlocks() {
			return this.value
				.filter((block) => block.status == "unavailable")
				.sort((a, b) => a.startTime.unix() - b.startTime.unix());
		},
	},
	methods: {
		deleteBlock(block) {
			let blocks = this.value.filter((a) => a !== block);
			this.$emit("input", blocks);
		},
	},
};
</script>
