<template>
	<v-sheet flat>
		<vc-calendar
			v-if="minPage"
			disable-page-swipe
			ref="calendar"
			nav-visibility="hidden"
			:available-dates="days"
			is-expanded
			@dayclick="dayclick"
			:min-page="minPage"
			:max-page="maxPage"
			:rows="rows"
			:attributes="Object.values(dots)"
		></vc-calendar>
		<v-menu
			v-model="menu"
			:position-x="positionX"
			:position-y="positionY"
			transition="slide-x-transition"
			:min-width="`${width}px`"
			:close-on-click="false"
			:close-on-content-click="false"
		>
			<mobile-scheduler-day
				v-if="day"
				:day="day"
				@close="closeDay"
				:value="getDayBlocks(day)"
				@input="(value) => updateBlocks(day, value)"
			></mobile-scheduler-day>
		</v-menu>
		<mobile-scheduler-footer :class="{'d-none': menu}"
			v-model="blocks"
			@save="save"
		></mobile-scheduler-footer>
		<mw-error v-model="error"></mw-error>
	</v-sheet>
</template>

<style lang="less" scoped>
.v-menu__content {
	box-shadow: none;
	border: 1px solid #cbd5e0;
	border-radius: 6px;
}
</style>
<script>
import Vue from "vue";
import VCalendar from "v-calendar";
Vue.use(VCalendar, {
	componentPrefix: "vc",
});
import MobileSchedulerDay from "@c/scheduler/MobileSchedulerDay";
import MobileSchedulerFooter from "@c/scheduler/MobileSchedulerFooter";
import MwError from "@c/ui/MwError";
export default {
	name: "MobileScheduler",
	components: {
		MobileSchedulerDay,
		MobileSchedulerFooter,
		MwError,
	},
	data: () => {
		return {
			menu: false,
			positionX: 0,
			positionY: 0,
			height: 0,
			day: null,
			width: "100%",
			blocks: [],
			error: false,
		};
	},
	inject: ["id", "from"],
	computed: {
		auth() {
			return this.$store.state.auth.data;
		},
		userId() {
			return this.auth.id || this.$route.query.q;
		},
		timezone() {
			return this.$store.state.auth.data.timezone;
		},
		project() {
			return this.$store.state.projects.data[this.id];
		},
		slots() {
			if (!this.project) {
				return [];
			}
			return this.project.slots;
		},
		days() {
			const self = this;
			let days = self.slots.flatMap((slot) => {
				let day = this.mwtimeutils.parseDateToMoment(
					slot.start,
					self.timezone
				);
				let end = this.mwtimeutils.parseDateToMoment(
					slot.end,
					self.timezone
				);
				let days = [];
				while (day < end) {
					days.push(day.clone());
					day.add(1, "days");
				}
				return days;
			});
			let today = this.mwtimeutils.parseDateToMoment(new Date());
			days = days.filter((day) => day > today);
			days = days.sort((a, b) => a.unix() - b.unix());
			days = days.map((day) => day.format("YYYY-MM-DD"));
			return this.mwutils.uniqueArray(days).map((date) => new Date(date));
		},
		minPage() {
			let date = this.months[0];
			if (date) {
				return { month: date[0], year: date[1] };
			}
			return null;
		},
		maxPage() {
			let date = this.months[this.months.length - 1];
			if (date) {
				return { month: date[0], year: date[1] };
			}
			return null;
		},
		months() {
			const self = this;
			let days = self.days.map((day) =>
				this.mwtimeutils.parseDateToMoment(day)
			);
			let months = days.map((day) => {
				return [day.month() + 1, day.year()];
			});
			let formattedMonths = months.map((m) => `${m[0] - m[1]}`);
			months = months.filter(
				(m, i) => formattedMonths.indexOf(`${m[0] - m[1]}`) == i
			);
			return this.mwutils.uniqueArray(months);
		},
		rows() {
			return this.months.length;
		},
		sessionLength(){
			return this.project ? this.project.sessionLength || 60 : 60;
		},
		quartersInSession() {
			return Math.ceil(this.sessionLength / 15);
		},
		dots() {
			let dots = {
				available: {
					key: "available",
					dates: [],
					dot: "green",
				},
				unavailable: {
					key: "unavailable",
					dates: [],
					dot: "red",
				},
				preferred: {
					key: "preferred",
					dates: [],
					dot: "blue",
				},
			};

			this.blocks.forEach((block) => {
				if (!dots[block.status].dates.includes(block.day)) {
					dots[block.status].dates.push(block.day);
				}
			});
			return dots;
		},
	},
	methods: {
		getDayBlocks(day) {
			return this.blocks.filter((block) => block.day == day);
		},
		updateBlocks(day, v) {
			let preferred = v.find( block => block.status == "preferred" );
			if( preferred ){
				this.blocks = this.blocks.filter( block => block.status !== "preferred" );
			}
			this.blocks = this.blocks.filter((block) => block.day !== day);
			this.blocks = this.blocks.concat(v);
		},
		closeDay() {
			this.day = null;
			this.menu = false;
		},
		dayclick(e) {
			if (!e.isDisabled) {
				this.menu = true;
				let position = this.$refs.calendar.$el.getBoundingClientRect();
				this.positionX = position.left;
				this.positionY = position.top;
				this.width = position.width;
				this.day = e.date;
			}
		},
		makeRanges() {
			const self = this;
			let ranges = self.blocks.map((block) => {
				return {
					status: block.status,
					start: block.startTime.toDate(),
					end: block.endTime.toDate(),
				};
			});
			return ranges;
		},
		save() {
			const self = this;
			let availability = self.makeRanges();
			let doc = {
				project: self.id,
				participant: self.userId,
				availability,
			};
			if (!self.userId) {
				self.error = true;
			} else {
				self.$store
					.dispatch("participantAvailability/insert", doc)
					.then(() => {
						self.$router.replace({
							name: "SchedulerThanks",
							params: { id: self.from },
						});
					})
					.catch(() => {
						self.error = true;
					});
			}
		},
	},
	created() {
		const self = this;
		window.addEventListener("orientationchange ", () => {
			self.menu = false;
		});
	},
};
</script>
