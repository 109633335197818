<template>
	<v-card flat>
		<v-card-title>
			{{ m.format("Do MMMM YYYY") }}
			<v-spacer></v-spacer>
			<v-btn @click="$emit('close')" icon><v-icon>mdi-close</v-icon></v-btn>
		</v-card-title>
		<v-card-text>
			<v-sheet flat class="agenda-day">
				<v-row>
					<v-col class="agenda-column"> </v-col>
					<v-col class="agenda-column">
						<div class="icon-button">
							<v-icon color="#a5c4fa"> mdi-heart</v-icon>
							<span>Preferred Slot</span>
						</div>
					</v-col>
					<v-col class="agenda-column">
						<div class="icon-button">
							<v-icon color="primary"> mdi-check-circle</v-icon>
							<span>I'm available</span>
						</div>
					</v-col>
					<v-col class="agenda-column">
						<div class="icon-button">
							<v-icon color="red"> mdi-close-circle</v-icon>
							<span>I'm unavailable</span>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="agenda-column">
						<div v-for="(time, i) in hours" :key="i" class="agenda-hour">
							<div>
								{{ time.moment.format("HH:mm") }}
							</div>
						</div>
					</v-col>
					<v-col class="agenda-column preferred-column" ref="agenda-col">
						<mobile-agenda-area
							status="preferred"
							:multiple="false"
							:value="preferred"
							@input="(value) => updateArea('preferred', value)"
							:timeseries="timeseries"
						></mobile-agenda-area>
					</v-col>
					<v-col class="agenda-column available-column">
						<mobile-agenda-area
							status="available"
							:value="available"
							:timeseries="timeseries"
							@input="(value) => updateArea('available', value)"
						></mobile-agenda-area>
					</v-col>
					<v-col class="agenda-column unavailable-column">
						<mobile-agenda-area
							status="unavailable"
							:value="unavailable"
							:timeseries="timeseries"
							@input="(value) => updateArea('unavailable', value)"
						></mobile-agenda-area>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="agenda-column"></v-col>
					<v-col class="agenda-column"></v-col>
					<v-col
						class="agenda-column available-column"
						style="text-align: center"
					>
						<v-btn
							x-small
							text
							class="mt-2 ml-2"
							@click="toggle('available')"
							>toggle<br/>all</v-btn
						>
					</v-col>
					<v-col
						class="agenda-column unavailable-column"
						style="text-align: center"
					>
						<v-btn
							x-small
							text
							class="mt-2 ml-2"
							@click="toggle('unavailable')"
							>toggle<br/>all</v-btn
						>
					</v-col>
				</v-row>
			</v-sheet>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn icon color="primary" large @click="done">
				<v-icon>mdi-check-circle</v-icon>
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<style lang="less">
// table.day {
// 	width: 100%;
// 	table-layout: fixed;
// 	border-spacing: 0px !important;
// }
.agenda-day {
	// position: relative;
	.agenda-hour {
		div {
			min-height: 40px;
			padding: 10px 20px;
		}
	}
	.agenda-hour::after {
		position: absolute;
		height: 1px;
		background-color: #f7f7f7;
		width: 100%;
		content: " ";
	}
	.agenda-column {
		border-right: 1px solid #f7f7f7;
		padding: 0px !important;
	}
}
.icon-button {
	text-align: center;
	padding: 20px 0px;
	.v-icon {
		display: block;
	}
	cursor: pointer;
	span {
		font-size: 10px;
		font-weight: normal;
		line-height: 1em;
	}
}
</style>

<script>
import MobileAgendaArea from "@c/scheduler/MobileAgendaArea.vue";

export default {
	name: "MobileSchedulerDay",
	props: {
		day: { type: [Object, String, Date] },
		value: { type: Array },
	},
	inject: ["id"],
	provide() {
		return {
			day: this.day,
		};
	},
	data: () => {
		return {
			selectAll: false,
			timeseries: [],
			inkingValue: null,
		};
	},
	components: {
		MobileAgendaArea,
	},
	computed: {
		m() {
			return this.mwtimeutils.parseDateToMoment(this.day, this.timezone);
		},
		available() {
			return this.value.filter((block) => block.status == "available");
		},
		unavailable() {
			return this.value.filter((block) => block.status == "unavailable");
		},
		preferred() {
			return this.value.filter((block) => block.status == "preferred");
		},
		timezone() {
			return this.$store.state.auth.data.timezone;
		},
		project() {
			return this.$store.state.projects.data[this.id];
		},
		slots() {
			const self = this;
			return this.project.slots.filter(
				(a) =>
					self.mwtimeutils
						.parseDateToMoment(a.start, this.timezone)
						.isSame(self.m, "day") ||
					self.mwtimeutils
						.parseDateToMoment(a.end, this.timezone)
						.isSame(self.m, "day")
			);
		},
		hours() {
			return this.timeseries.filter((t) => t.moment.minutes() == 0);
		},
		earliest() {
			return this.timeseries[0];
		},
		latest() {
			return this.timeseries[this.timeseries.length - 1];
		},
	},
	methods: {
		makeTimeseries() {
			let ts = this.mwtimeutils.getQuartersFromSlots(
				this.slots,
				this.timezone
			);
			this.timeseries = ts.map((t) => {
				return {
					moment: t,
					status: "",
				};
			});
		},
		done() {
			this.$emit("close");
		},
		toggle(status) {
			if (this[status].length == 0) {
				this.updateArea(status, [
					{
						day: this.day,
						status,
						startAt: 0,
						endAt: this.timeseries.length - 1,
					},
				]);
			} else {
				this.updateArea(status, []);
			}
		},
		updateArea(key, v) {
			const self = this;
			let value = [...this.value];
			value = value.filter((a) => a.status !== key);
			value = value.concat(v);
			value.forEach((a) => {
				if( a.endAt >= self.timeseries.length ){
					a.endAt = self.timeseries.length - 1;
				}
				a.startTime = self.timeseries[a.startAt].moment;
				a.endTime = self.timeseries[a.endAt].moment;
			});
			this.$emit("input", value);
		},
	},
	watch: {
		day: {
			immediate: true,
			handler() {
				this.makeTimeseries();
			},
		},
	},
};
</script>
