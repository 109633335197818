<template>
	<mw-dialog small :value="value" @input="(v) => $emit('input', v)">
		<template v-slot:dialog-body>
			Something went wrong there. Please contact Meisterworks.
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "MwError",
	props: {
		value: { type: Boolean },
	},
	components: {
		MwDialog,
	},
};
</script>
