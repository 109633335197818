<template>
	<v-sheet flat>
        <render-content id="schedulingheader"></render-content>
		<mobile-scheduler></mobile-scheduler>
	</v-sheet>
</template>

<script>
import RenderContent from "@c/ui/RenderContent";
import MobileScheduler from "@c/scheduler/MobileScheduler";
export default {
	name: "Scheduler",
	props: {
		id: { type: String },
		from: { type: String },
	},
	data() {
		return {};
	},
	provide() {
		return {
			id: this.id,
			from: this.from,
		};
	},
	methods: {
		update(k, v) {
			this[k] = v;
		},
	},
	components: {
		RenderContent,
		MobileScheduler
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
	},
	created() {
		this.$store.dispatch("projects/fetchById", this.id);
	},
};
</script>
