<template>
	<vue-drag-resize
		class="v-card agenda-block"
		:class="{
			[status]: true,
		}"
		v-if="blockHeight"
		:w="gridWidth"
		:minw="gridWidth"
		:parent-w="gridWidth"
		:h="blockHeight"
		:y="offsetTop"
		:x="0"
		:is-active="true"
		@dragstop="resize"
		:snap-to-grid="true"
		:grid-y="gridY"
		@resizestop="resize"
		:is-resizable="resizable"
		:parent-limitation="true"
		@clicked="click"
		:sticks="['tm', 'bm']"
		:stick-size="15"
	>
		<v-btn icon absolute right class="delete-button" color="white" x-small>
			<v-icon class="delete-button">mdi-close</v-icon>
		</v-btn>
	</vue-drag-resize>
</template>
<style lang="less">
@import "./../../assets/css/variables.less";
.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
	right: 2px;
	top: 2px;
}

.agenda-block {
	width: 100%;
	position: absolute;
	&.unavailable {
		background-color: lighten(@error, 20%);
	}
	&.available {
		background-color: lighten(@primary, 20%);
	}
	&.preferred {
		background-color: #a5c4fa;
	}
}

.vdr-stick {
	background: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
	display: inline-block;
	font: normal normal normal 24px/1 "Material Design Icons";
	// font-size: 24px !important;
	text-rendering: auto;
	font-size: medium !important;
	line-height: inherit;
	-webkit-font-smoothing: antialiased;
}
.vdr-stick-tm:before {
	content: "\F005D";
	position: relative;
	top: -8px;
}
.vdr-stick-bm:before {
	content: "\F0045";
	position: relative;
}
</style>
<script>
import Vue from "vue";
import VueDragResize from "vue-drag-resize";

Vue.component("vue-drag-resize", VueDragResize);

export default {
	name: "MobileAgendaBlock",
	props: {
		startAt: { type: Number },
		endAt: { type: Number },
		status: { type: String },
		gridHeight: { type: Number },
		gridWidth: { type: Number },
		rowHeight: { type: Number },
		timeseries: { type: Array, default: () => [] },
	},
	data: () => {
		return {};
	},
	computed: {
		offsetTop() {
			return Math.ceil(this.startAt * this.gridY);
		},
		gridY() {
			return this.gridHeight * (this.rowHeight / 100);
		},
		blockHeight() {
			return Math.ceil(this.gridY * (this.endAt - this.startAt));
		},
		icon() {
			let icons = {
				preferred: "mdi-heart",
			};
			return icons[this.status];
		},
		resizable() {
			return this.status !== "preferred";
		},
	},
	methods: {
		focus() {
			this.focused = true;
		},
		resize(newRect) {
			let start = parseInt(newRect.top / this.gridY);
			let height = parseInt(newRect.height / this.gridY);
			let end = height + start;

			if (start !== this.startAt) {
				this.$emit("change", "startAt", start);
			}
			if (end !== this.endAt) {
				this.$emit("change", "endAt", end);
			}
		},
		click(event) {
			if (event.target.classList.contains("delete-button")) {
				this.$emit("deleteBlock");
			}
		},
	},
};
</script>
