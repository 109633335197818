<template>
	<div class="agenda-area" ref="draggable-container">
		<template v-for="(block, i) in value">
			<mobile-agenda-block
				:key="`block-${status}-${i}`"
				v-bind="block"
				:status="status"
				:gridHeight="gridHeight"
				:timeseries="timeseries"
				:rowHeight="rowHeight"
				@change="(key, v) => (block[key] = v)"
				@deleteBlock="deleteBlock(i)"
				:gridWidth="gridWidth"
			>
			</mobile-agenda-block>
		</template>

		<div
			class="agenda-quarter"
			v-for="(_, i) in timeseries"
			:key="i"
			@click="newBlock(i)"
			:style="{ height: `${rowHeight}%` }"
		></div>
	</div>
</template>

<style lang="less">
.agenda-area {
	height: 100%;
	position: relative;
}
.deleteBtn {
	position: relative;
}
</style>
<script>
import MobileAgendaBlock from "@c/scheduler/MobileAgendaBlock";
export default {
	name: "MobileAgendaArea",
	props: {
		value: { type: Array },
		timeseries: { type: Array, default: () => [] },
		status: { type: String },
		multiple: { type: Boolean, default: true },
	},
	inject: ["id", "day"],
	data: () => {
		return {
			gh: null,
			gw: null,
		};
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
		sessionLength(){
			return this.project ? this.project.sessionLength || 60 : 60;
		},
		quartersInSession() {
			return Math.ceil(this.sessionLength / 15);
		},
		rowHeight() {
			return 100 / this.timeseries.length;
		},
		gridWidth() {
			return this.$refs["draggable-container"] ? this.$refs["draggable-container"].clientWidth : this.gw;
		},
		gridHeight() {
			return this.$refs["draggable-container"] ? this.$refs["draggable-container"].clientHeight : this.gh;
		},
	},
	methods: {
		newBlock(i) {
			let block = {
				startAt: i,
				endAt: null,
				day: this.day,
				status: this.status,
			};
			block.endAt = i + this.quartersInSession;
			if (this.multiple) {
				let blocks = [...this.value];
				blocks.push(block);
				this.$emit("input", blocks);
			} else {
				this.$emit("input", [block]);
			}
		},
		deleteBlock(i) {
			let blocks = [...this.value];
			blocks.splice(i, 1);
			this.$emit("input", blocks);
		},
		updateDimensions() {

			this.gh = this.$el.getBoundingClientRect().clientWidth;
			this.gw = this.$el.getBoundingClientRect().clientHeight;

		},
	},
	mounted() {
		const self = this;
		setTimeout( function (){
			self.updateDimensions()
		}, 100 )
	},
	components: {
		MobileAgendaBlock,
	},
};
</script>
